.Container{
  width:100%;
  height: 193px;
  background: #00b578;
  padding: 0 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../asset/image/bg_head.png") ;
  background-repeat:no-repeat;
  background-size: 100% 100%;
  .RightHeader{
    position: absolute;
    right: 15px;
    top:20px;
  }
  .BackAndMore{
    display: flex;
    width: 100%;
    height: 61px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ConnectBtn{
    width: 69px;
    height: 31px;
    background: rgba(0,0,0,0.84);
    border-radius: 17px;
    position: absolute;
    right: 15px;
    top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
  }
  .FilterBtn{
    position: absolute;
    width: 19px;
    height: 19px;
    top: 68px;
    right: 20px;
    color:white;
  }
  .HeaderName{
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 25px;
    margin-top: 62px;
    display: flex;
    flex-direction: row;
    .activeView{
      color: #38ca9f;
      cursor: pointer;
    }
    .view{
      color: white;
      cursor: pointer;
    }
  }
  .SearchContainer{
    width:100%;
    height:38px;
    margin-top: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .searchIcon{
      position: absolute;
      left: 0;
      top: 0;
      width: 34px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :global(.adm-input-element){
      background: #FFFFFF;
      border-radius: 8px;
      opacity: 0.88;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 34px;
    }
  }
}
