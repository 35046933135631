@import "react-virtualized/styles.css";
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
    -webkit-overflow-scrolling: touch;
    -overflow-scrolling: touch;
  }
}
.container{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100vmin;
  height: calc(100vh - 50px);//多留70px
  //padding-bottom: 70px;
  overflow: auto;
}
#containerMap{
  height: 100%;
}
.maxContainer{
  display: flex;
  flex-direction: column;
  flex: 1;
  //padding-bottom: 70px;
  overflow: auto;
  background: #161b24;
  overflow-x:hidden ;
}
.paddingBottom70{
  padding-bottom: 70px;
}
.wrapText{
  flex-wrap: wrap;
  text-wrap: normal;
  word-break: break-all;
}
.NavBar{
  background-color: #f6f5f6;
  .adm-nav-bar-back-arrow{
    font-size: 20px;
    color: #333;
  }
}
html{
  height:100%;
  overflow-x: hidden;
}
#root{
  display: flex;
  height:100%;
}
body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
    overscroll-behavior-y: contain;
}

