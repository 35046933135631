.Container{
  width: 100%;
  height: 84px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  .InfoContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 10px;
    .Name{
      color:#fff;
      font-size: 20px;
      margin-bottom: 3px;
    }
    .Address{
      font-size: 14px;
      color:#A1A5AE;
    }
  }
  .SubscribeBtn{
    font-size: 14px;
    --background-color:#38CA9F;
    --border-style:0;
    --text-color:white;
  }
  .ReSubscribeBtn{
    font-size: 14px;
    opacity: 100%;
    --background-color:#686A79;
    --border-style:0;
    --text-color:white;
  }
}
