.container{
  padding: 5vmin 2px;
  display: flex;
  :global(.adm-list){
    width: 100%;
  }

  :global(.adm-list-item-content){
    border-top: 0;
  }
  :global(.adm-list-item-description){
    font-size: 12px;
  }

  :global(.adm-list-body){
    border-top: 0;
    width: 100%;
  }

  :global(.adm-list-item){
    line-height: 1.2;
    padding-left: 0;
  }

  .title_h4
  {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

  }

}
