.Container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 15px;
  background: #161B24 ;
  --adm-color-background:#161B24;
  color:white;
  .itemContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 15px 0;
    .platformInfo{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      justify-content: flex-start;
      .platformInfo_type{
        width: 40px;
        height: 20px;
        //margin-right: 11px;
      }
      .platformInfo_platform{
        width: 16px;
        height: 16px;
        //margin-right: 8px;
      }
    }
    .firstTitle{
      display: flex;
      flex: 1;
      flex-direction: row;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      margin-bottom: 7px;
      flex-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis ; //省略
      .content{
        font-size: 16px;
        color:#BABBBE;
        //margin: 0 8px;
        font-weight: 500;
        flex-wrap: nowrap;
        white-space: nowrap;
      }
      .last{
        flex: 1;
        flex-wrap: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis ; //省略
      }
    }
    .secondTitle{
      margin-bottom: 7px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
    }
    .content{
      font-size: 12px;
      font-weight: 400;
      color: #BABBBE;
      line-height: 20px;
      word-break: break-all;
    }
  }
  :global(.adm-list){
    --border-inner: 0;
    --border-top:0;
  }
  :global(.adm-list-default .adm-list-body){
    border-bottom: 0 !important;
  }
}
.Loading {
  :global(.adm-spin-loading) {
    position: fixed;
    z-index: 1000;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.userCards{
  padding: 3vmin 5vmin;
}

.fromChainInfo{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

}

.wrapText
{
  align-self: center;

}

.title
{
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 22px;
  color:#BABBBE;
  margin: 0;
  font-weight: 500;
}


.title_h4
{
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
  margin-bottom: 7px;
}

:global(.adm-pull-to-refresh){
  touch-action: pan-y;
}
:global(.adm-list-item-content-main){
  width: 100%;
  padding: 0;
}



