.Container{
  width: 100%;
  height: 100%;
  background: #2D3137;
  padding: 22px 16px 0;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 16px;
  line-height: 22px;
  overflow-y: auto;
  .Title{
    font-size: 16px;
    color: #E7E8EC;
    line-height: 22px;
  }
  .TagsContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 14px;
    .TagsBtn{
      --border-radius:8px;
      --background-color: #474B51;
      --border-width:0;
      --text-color:white;
      padding: 6px 15px;
      margin: 0 10px 20px 0;
    }
    .TagsBtnSelected{
      --border-radius:8px;
      --background-color: #38CA9F;
      --border-width:0;
      --text-color:white;
      padding: 6px 15px;
      margin: 0 10px 20px 0;
    }
  }
  .Divider{
    margin: 12px 0;
    border: 1px solid rgba(255,255,255,0.08);
    height:0;
  }
  .RadiosContainer{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .DropDown{
    margin: 12px 0;
    height: 38px;
    border-radius: 10px;
    :global(.css-1s2u09g-control){
      background: #161B24;
      border:0 !important;
      outline:0;
      box-shadow: 0 0 0 0 !important;
    }
    :global(.css-1pahdxg-control){
      background: #161B24;
      border:0 !important;
      outline:0;
      box-shadow: 0 0 0 0 !important;
    }
    :global(.css-1okebmr-indicatorSeparator){
      width: 0;
    }
  }
  .SelectedContainer{
    width: 100%;
    //background: #00b578;
    padding: 13px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
      width: 40px;
      height: 40px;
    }
    .Title{
      margin:0 10px ;
      font-size: 16px;
      font-weight: 500;
      color: #E7E8EC;
      line-height: 22px;
      flex:1
    }
  }

}
