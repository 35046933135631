.Container{
  width: 100%;
  height: 100vh;
  background: #161B24;
  padding: 0 15px;
  color: white;
  display: flex;
  flex-direction: column;
  :global(.adm-nav-bar){
    padding: 0;
  }
  .DetailAndRemoveAll{
    display: flex;
    width: 100%;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #838791;
    line-height: 22px;
    margin: 22px 0 12px 0;
    .Detail{
      flex: 1;
    }
    .RemoveAll{
      height: 20px;
      font-size: 14px;
      color: #828BC4;
      line-height: 20px;
    }
  }
  .overAuto{
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }
  .ListItemContainer{
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    .ItemInfo{
      flex: 1;
      .ItemName{
        height: 28px;
        font-size: 20px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 28px;
      }
      .ItemAddress{
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #A1A5AE;
        line-height: 20px;
      }
    }
  }
}
